// 定期报告
import SHZT from './sn-table-column-shzt';
import DJZT from './sn-table-column-djzt';

const selfData = {
    loading: false,
    snTable: {
        expand: false,
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    prop: 'MC',
                    label: '名称',
                    minWidth: 200,
                    showoverflowtooltip: true
                }, {
                    prop: 'GBSJ',
                    label: '公布时间',
                    minWidth: 120,
                }, {
                    prop: 'LB',
                    label: '类别',
                    minWidth: 120
                }, {
                    prop: 'ZT',
                    label: '状态',
                    minWidth: 50
                }, {
                    prop: 'LJ',
                    label: '文件内容',
                    minWidth: 90,
                    showFileBtn: true,
                },
                SHZT,
                DJZT
            ],
            columnsBtn: {
                buttonData: []
            },
        },

    },
    snButton: {
        buttonData: [
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
        selectData: [
            // {
            //     "label": "类别",
            //     "clearable": true,
            //     "placeholder": "请选择类别",
            //     "list": [
            //         { "ID": "专项保护管理法规、规章", "NAME": "专项保护管理法规、规章" },
            //         { "ID": "其他", "NAME": "其他" }
            //     ],
            //     "optionLabel": "NAME",
            //     "optionValue": "ID",
            //     "value": "LB",
            //     "operateType": "search",
            //     "isShow": true
            // },
            // {
            //     "label": "状态",
            //     "clearable": true,
            //     "placeholder": "请选择状态",
            //     "list": [
            //         { "ID": "生效", "NAME": "生效" },
            //         { "ID": "废止", "NAME": "废止" }
            //     ],
            //     "optionLabel": "NAME",
            //     "optionValue": "ID",
            //     "value": "ZT",
            //     "operateType": "search",
            //     "isShow": true
            // }
        ],
        searchBtn: {
            buttonData: [{
                btnType: 'button',
                operateType: 'searchBtn',
                name: '查询',
                round: true,
                backColor: '#2a63d5',
                color: '#fff'
            }]
        }
    }
};
export default selfData;